<template>
  <div class="container">
    <h1 style="text-align:center;">הזמנת שקיות מחזור בקבוקים</h1>
    <div class="warpper">
      <div class="field">
        <p>שם מלא של העובד</p>
        <InputText
          v-model="values.fullName"
          type="text"
          placeholder="שם מלא"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>מס' ת"ז</p>
        <InputText
          v-model="values.idNumber"
          type="text"
          placeholder="מס' זהות"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>שם פותח/ת הבקשה</p>
        <InputText
          v-model="values.ownerName"
          type="text"
          placeholder="הזן/י את שמך"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>דוא"ל לעדכון התהליך</p>
        <InputText
          v-model="values.email"
          type="text"
          placeholder="דואל"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>בחירת שקיות מחזור</p>
        <MultiSelect v-model="values.selected_cycle_options" :options="cycle_options" optionLabel="name" optionValue="qnt" placeholder="בחירת שקי מחזור" />
      </div>
      <template v-for="option in values.selected_cycle_options" :key="option.name">
        <div class="item">
            <h3>{{option.name}}</h3>
            <el-input-number  v-model="option.qnt" :min="1" :max="100" />
        </div>
      </template>  
      
      
    </div>
    <Button
      label="שלח"
      class="p-button-help send-btn"
      @click="handleSubmit"
    />
  </div>

  <!-- JUST FOR MAIL -->
  <div class="form-email" id="form-email" v-show="false">
    <table
      style="width:50%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
    >
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          תאריך הבקשה
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.createdAt.toLocaleDateString("he") }},{{
            values.createdAt.toLocaleTimeString("he")
          }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding:8px;">
          שם הסניף השולח
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding:8px;">
          {{ values.branche }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          סוג הבקשה
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.type }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          שם העובד/ת
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.fullName }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          מס' ת"ז של העובד/ת
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.idNumber }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          שם פותח הבקשה
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.ownerName }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          אימייל לעדכון סטטוס וקבלת עדכונים
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.email }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          סטטוס
        </th>
        <td
          style="border:1px solid #dddddd; text-align:right; padding: 8px; color:green"
        >
          {{ values.status }}
        </td>
      </tr>
    </table>
    <table style="margin-top:5px; width:100%;">
        <tr style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">שם שקית מחזור</th>
            <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">כמות</th>
        </tr>
        <template v-for="item in values.selected_cycle_options" :key="item.name">
            <tr style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">{{item.name}}</td>
                <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">{{item.qnt}}</td>
            </tr>
        </template>
    </table>
  </div>
</template>

<script>
/* eslint-disable */
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import MultiSelect from 'primevue/multiselect';
import swal from "sweetalert";
import {projectFirestore,projectFunctions} from '../../../../../firebase/config'
import {increment} from '@/Methods/office_request_funcs'
import InputNumber from 'primevue/inputnumber';
import { onMounted, ref } from "vue";
import store from '../../../../../store';

export default {
  components: { InputText,Textarea,MultiSelect,InputNumber },
  props:['docId'],
  setup(props) {
    
    const cycle_options = ref([
        {
            name:'שק כחול – זכוכית 330 מ"ל',
            qnt:{
                name:'שק כחול – זכוכית 330 מ"ל',
                qnt:1
            }   
        },
        {
            name:'שק ירוק – זכוכית גדול',
            qnt:{
                name:'שק ירוק – זכוכית גדול',
                qnt:1
            }   
        },
        {
            name:'שק צהוב – פלסטיק עד 1.5 ליטר',
            qnt:{
                name:'שק צהוב – פלסטיק עד 1.5 ליטר',
                qnt:1
            }   
        },
        {
            name:'שק ורוד – פחיות',
            qnt:{
                name:'שק ורוד – פחיות',
                qnt:1
            }   
        },
        {
            name:'שק שקוף – פלסטיק 1.5 ליטר ומעלה',
            qnt:{
                name:'שק שקוף – פלסטיק 1.5 ליטר ומעלה',
                qnt:1
            }   
        },
    ])
    const isPending = ref(false);
    const error = ref("");
  
    const values = ref({
      fullName: "",
      idNumber: "",
      ownerName: "",
      email: "",
      selected_cycle_options:[],
      branche: "",
      charge:"לא הוגדר",
      type: "מחזור בקבוקים",
      status: "חדש",
      createdAt: new Date()
    });

    const validation = () => {
      for (let key in values.value) {
        if (key == "fullName") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא שם מלא של העובד";
            return false;
          }
        }
        if (key == "idNumber") {
          if (values.value[key].length <7) {
            error.value = "נא למלא מספר זהות או דרכון תקין";
            return false;
          }
        }
        if (key == "ownerName") {
          if (values.value[key].length < 1) {
            error.value = "נא למלא את שם פותח הבקשה";
            return false;
          }
        }
        if (key == "email") {
          if (
            values.value[key].length == 0 ||
            !values.value[key].includes("@")
          ) {
            error.value = "נא למלא כתובת מייל תקינה";
            return false;
          }
        }
        if (key == "selected_cycle_options") {
          if (values.value[key].length == 0) {
            error.value = "עליך לבחור שקי מחזור  - לפחות אחד";
            return false;
          }
        }
      
      }
      return true;
    };

    const handleSubmit = async () => {
        if (!validation()) {
            swal("בעיה", `${error.value}`, "warning");
        } else {
            const doc = projectFirestore.collection('Applications').doc(props.docId).collection('Cycle').doc()
            await doc.set({ ...values.value, id: doc.id });
            await increment(props.docId)
            sendEmailToUser();
            sendEmailToCharge();
            clearData();
            swal("הצלחה", "הטופס נשלח בהצלחה", "success");
        }
        clearData()
    };

    function sendEmailToUser() {
      projectFunctions.httpsCallable('send_email')({
        html: `<div>
                <h3>
                    שלום ${values.value.ownerName},
                </h3>
                <h3>
                    בקשתך המשרדית לעובד: ${values.value.fullName}
                </h3>
                <h3>
                    עבור: ${values.value.type}
                </h3>
                <h3>
                    לסניף/אתר ${values.value.branche}
                </h3>
                <h3>
                    נשלחה לטיפול!
                </h3>
                <h3 style="color:red;">
                    נא לשים לב להמשך עדכונים שיתקבלו אוטמטית מהמערכת בכל שלבי הטיפול!
                </h3>
            </div>`,
        destinations:[`${values.value.email}`],
        from:"מערכת לבקשות משרדיות",
        title:"נפתחה בקשה משרדית חדשה"
      }) 
    }
    function sendEmailToCharge() {
      projectFunctions.httpsCallable('send_email')({
        html: document.getElementById("form-email").innerHTML,
        destinations:['rosman.mate@gmail.com","ravit@rosman.co.il'],
        from:"מערכת לבקשות משרדיות",
        title:"נפתחה בקשה משרדית חדשה"
      })
    }

    const clearData = () => {
      values.value = {
        fullName: "",
        idNumber: "",
        ownerName: "",
        email: values.value.email,
        selected_cycle_options:[],
        branche: values.value.branche,
        charge:"לא הוגדר",
        type: "מחזור בקבוקים",
        status: "חדש",
        createdAt: new Date()
      };

      cycle_options.value = [
        {
            name:'שק כחול – זכוכית 330 מ"ל',
            qnt:{
                name:'שק כחול – זכוכית 330 מ"ל',
                qnt:1
            }   
        },
        {
            name:'שק ירוק – זכוכית גדול',
            qnt:{
                name:'שק ירוק – זכוכית גדול',
                qnt:1
            }   
        },
        {
            name:'שק צהוב – פלסטיק עד 1.5 ליטר',
            qnt:{
                name:'שק צהוב – פלסטיק עד 1.5 ליטר',
                qnt:1
            }   
        },
        {
            name:'שק ורוד – פחיות',
            qnt:{
                name:'שק ורוד – פחיות',
                qnt:1
            }   
        },
        {
            name:'שק שקוף – פלסטיק 1.5 ליטר ומעלה',
            qnt:{
                name:'שק שקוף – פלסטיק 1.5 ליטר ומעלה',
                qnt:1
            }   
        },
      ]
    };

    onMounted(() => {
      setTimeout(() => {
        if(store.getters.user.branche){
          values.value.branche = store.getters.user.branche;
        }else{
          values.value.branche = store.getters.user.display_name;
        }
        values.value.email = store.getters.user.email
      }, 1000);
    });

    return {
      values,
      error,
      handleSubmit,
      isPending,
      cycle_options,
    };
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.warpper {
  width: 50%;
  height: calc(100% - 100px);
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.field {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px;
  margin-top: 15px;
}
.item{
    width: 100%;
    height: fit-content;
    display: flex;
    margin: 5px 0;
    justify-content: space-between;
    padding: 0 5px;
    align-items: center;
}
.item h3{
    width: 69%;
}
.images {
  width: 100%;
  height: max-content;
  max-height: max-content;
  min-height: 100px;
  padding: 3px;
  display: flex;
  margin-top: 5px;
  overflow: hidden;
}
.images .image {
  position: relative;
  width: calc(100% / 3);
  height: 100%;
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 5px;
}
.images .image .delete-bar {
  margin-top: 10px;
  width: 100%;
  height: 30px;
  background-color: rgb(145, 3, 3);
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 5px;
}
.images .image img {
  max-width: 100%;
  max-height: calc(100% - 40px);
  border-radius: 5px;
}
.field p {
  font-size: 1.1rem;
}
.send-btn {
  margin-top: 5px;
  width: 50%;
}
input[type="date"] {
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
input[type="date"]:hover {
  border: 1.1px solid lightblue;
}
input[type="date"]:focus {
  outline: none;
  border: 1.1px solid lightblue;
}
input[type="time"] {
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
input[type="time"]:hover {
  border: 1.1px solid lightblue;
}
input[type="time"]:focus {
  outline: none;
  border: 1.1px solid lightblue;
}
.upload-btn {
  width: 30%;
}

@media screen and (max-width: 600px) {
   .container {
    justify-content: unset;
    overflow: hidden;
    background: var(--secondary);
    color: #fff;
  }
  .warpper {
    width: 100%;
    border: none;
    overflow-y: auto;
    flex-shrink: 0;
  }
  .send-btn {
    width: 100%;
  }
  .upload-btn {
    width: 100%;
  }
}
</style>
