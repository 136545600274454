import {projectFirestore,firebase} from '../firebase/config'

export const increment = async (docId)=>{
    await projectFirestore.collection('Applications').doc(docId)
    .update({
        news:firebase.firestore.FieldValue.increment(1),
        total:firebase.firestore.FieldValue.increment(1)
    })
}

export const decrement = async (status,docId)=>{
    await projectFirestore.collection('Applications').doc(docId)
    .update({
        [convert_status(status)]:firebase.firestore.FieldValue.increment(-1),
        total:firebase.firestore.FieldValue.increment(-1)
    })
}

export const update_status_counter = async(old_status,new_status,docId)=>{
    await projectFirestore.collection('Applications').doc(docId)
    .update({
        [convert_status(old_status)]:firebase.firestore.FieldValue.increment(-1),
        [convert_status(new_status)]:firebase.firestore.FieldValue.increment(1)
    })
}

export const convert_type_he_to_en=(type)=>{
    switch (type) {
        case 'שינוי פרטי חשבון בנק':
            return 'Bank_account'
        case 'כרטיס עובד':
            return 'Employee_card'
        case 'בקשה כללית':
            return 'General_request'
        case 'הקפה':
            return 'Hakafa'
        case 'תיקוני שעות':
            return 'Hourly_corrections'
        case 'הגדלת שעות':
            return 'Increasing_Hours'
        case 'אישור מחלה לעובד':
            return 'Ishur_machala'
        case 'התפטרות':
            return 'Itpatrut'
        case 'קבלת מפרעה':
            return 'Mifraha'
        case 'עדכון פרטים אישיים':
            return 'Personal_Details'
        case 'פיטורים':
            return 'Piturim'
        case 'פריטה':
            return 'Prita'
        case 'שימוע':
            return 'Shimua'
        case 'שעות עבודה לעובד זמני':
            return 'Temporary_worker'
        case 'העברת עובד':
            return 'Transfer_employee'
        case 'תאונת עבודה':
            return 'Work_accident'
        case 'טפסים לעובד':
            return 'Worker_forms'
        case 'עדכון כתובת אימייל':
            return 'UpdateEmail'
        case 'מחזור בקבוקים':
            return 'Cycle'
        case 'הזמנת שקיות מחזור בקבוקים':
            return 'Cycle'
        case 'ציוד משרדי':
            return 'OfficeEquipment'
    }
}

const convert_status=(status)=>{
    let after_convert=''
    switch (status) {
        case 'חדש':
           after_convert='news'
            break;
        case 'ממתין':
            after_convert='waiting'
            break;
        case 'טופל':
            after_convert='old'
            break;
        default:
            break;
    }

    return after_convert
}